import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import emailjs from "emailjs-com";
import "./Contact.css";
import Social from "../../components/Social";
import { Helmet } from "react-helmet";
import { FaPaperPlane } from "react-icons/fa";

function Contact() {
  // const { handleChange, values, handleSubmit, errors } = useForm(validate);
  const [active, setActive] = useState(false);
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_or3lfxw",
        e.target,
        "user_ZlbtZBhAWktBF4FbWKyl5"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div
      className='main-container'
      id='work-main'
      style={{
        overflowY: active ? "hidden" : "",
        height: active ? "100vh" : "",
        background: "#17181c",
      }}>
      <Navbar active={active} setActive={setActive} />
      <section id='contact'>
        <div className='contact-container'>
          <div className='contact-img'>
          <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
{/* <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_isbiybfh.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop controls autoplay></lottie-player> */}
            {/* <script src='https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js'></script> */}
            <lottie-player
              src='https://assets3.lottiefiles.com/packages/lf20_isbiybfh.json'
              background='transparent'
              speed='1'
              style={{ minWidth: "500px", height: "100%" }}
              loop
              autoplay></lottie-player>
          </div>
          <Helmet>
            <title>Arash Arora - Contact</title>
            <meta
              name='description'
              content="Hey!, I'm Arash Arora, this is my contact page"
            />
            <meta property='og:site_name' content='arasharora.com' />
            <meta property='og:site_name' content='Contact - Arash Arora' />
            <meta
              name='keywords'
              content='Arash, Arash Arora, Arash Portfolio, Arash Manav Rachna, Arora Arash, Arash Arora contact'
            />
          </Helmet>
          <div className='contact-wrapper'>
            <h1 class='section-header'>Reach Me</h1>
            <form className='contact-form' onSubmit={sendEmail}>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='NAME'
                  name='name'
                  required
                />
              </div>
              <div className='form-group'>
                <input
                  type='email'
                  className='form-control'
                  placeholder='EMAIL'
                  name='email'
                  required
                />
              </div>
              <div className='form-group'>
                <textarea
                  name='text'
                  rows='10'
                  placeholder='MESSAGE'
                  className='form-control'
                  required
                />
              </div>
              <button className='send-btn' type='submit' value='SEND'>
                <div class='alt-send-button'>
                  <FaPaperPlane className='paper-plane' />
                  <span className='send-text'>SEND</span>
                </div>
              </button>
              <div className='contact-social'>
                <Social />
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
