import React from "react";
import { Link } from "react-router-dom";
// import { Link as LinkS } from "react-scroll";
// import { NavLinks } from "./NavbarElements";

function LinkList(props) {
  console.log();
  return (
    <ul>
      <li>
        <Link to='/profile' style={{ "--i": "0.2s" }}>
          My Profile
        </Link>
      </li>
      <li>
        <a
          href='https://arasharora2001.medium.com/'
          target='_blank'
          style={{ "--i": "0.3s" }}
          rel='noreferrer'>
          Blogs
        </a>
      </li>
      <li>
        <a
          href='https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3884373'
          target='_blank'
          style={{ "--i": "0.4s" }}
          rel='noreferrer'>
          My Publications
        </a>
      </li>
      <li>
        <Link to='/work' style={{ "--i": "0.5s" }}>
          Work
        </Link>
      </li>
      {/* <li>
        <Link to='/journal' style={{ "--i": "0.6s" }}>
          Journal
        </Link>
      </li> */}

      <li>
        <Link target='_blank' to='/contact' style={{ "--i": "0.7s" }}>
          Reach Me
        </Link>
      </li>
    </ul>
  );
}

export default LinkList;
