import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

function Social() {
  return (
    <div className='social'>
      <ul>
        <li className='round'>
          <a href='https://www.facebook.com/arash.arora.5'>
            <FaFacebookF />
          </a>
        </li>
        <li className='round'>
          <a href='https://www.instagram.com/arash_arora/'>
            <FaInstagram />
          </a>
        </li>
        <li className='round'>
          <a href='https://github.com/arash-arora/'>
            <FaGithub />
          </a>
        </li>
        <li className='round'>
          <a href='https://www.linkedin.com/in/arash-arora-ab344518b/'>
            <FaLinkedinIn />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Social;
